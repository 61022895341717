import React, { useMemo } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import useResourceLoader from '../util/useResourceLoader';
import UWEEntityApi from '../api/UWEEntityApi';
import { DATETIME_FORMAT } from '../constants';
import Notification from '../components/Notification';
import Loader from '../components/Loader';


function UWEEntityHistory({ entity, totalExpanded }) {
    const entityId = entity?.id;

    const [history, loadingHistory, error] = useResourceLoader(
        () => entityId ? UWEEntityApi.getUWEEntityHistory({ id: entityId }).then(data => {
            return data.map(item => ({...item, date: dayjs(item.timestamp).utc(true).local() }));
        }) : [],
        [entityId],
        []
    );

    return loadingHistory ? <Loader /> : (
        <>
            {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
            {!totalExpanded ?
                <div className="uweentity-history-list">
                    {history.length ? history.map((entry, idx) => (
                        <SidePanelHistoryEntry key={idx} entry={entry} />
                    )) : (
                        <Notification color="warning">Historia vacía</Notification>
                    )}
                </div>
                :
                <div className="uweentity-history-expanded">
                    <ul className="striped-list">
                        {history.length ? history.map((entry, idx) => (
                            <MaximizedHistoryEntry key={idx} entry={entry} />
                        )) : (
                            <Notification color="warning">Historia vacía</Notification>
                        )}
                    </ul>
                </div>
            }
        </>
    );
}


function SidePanelHistoryEntry({ entry: { date, user: { name }, status, option, reason } }) {
    return (<div className="entry">
        <div className='timestamp'>{date? date.format(DATETIME_FORMAT): " "}</div>
        <div className='username'>{name}</div>
        <div className="action">{status}</div>
        {option ? <div className="option">{option}</div> : null}
        {reason && reason.trim() ? <div className="reason">{reason}</div> : null}
    </div>);
}


function MaximizedHistoryEntry({ entry: { date, status, reason } }) {
    return (<li className="uweentity-history">
        <div className='row'>
            <div className='col-lg-2'><span className="date-time"> {date? date.format(DATETIME_FORMAT): " "}</span></div>
            <div className='col-lg-10'>
                <span className="text">{status}</span>
                {reason ? ` - ${reason}` : null}
            </div>
        </div>
    </li>);
}

export default UWEEntityHistory;
