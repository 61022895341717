import React, { useMemo, useState } from "react";
import { Table, Button, Input, Badge } from "reactstrap";
import LookupApi from "../../api/LookupApi";
import SettingsManagementApi from "../../api/SettingsManagementApi";
import StaticFileApi from "../../api/StaticFileApi";
import FileInput from "../../components/FileInput";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import { SLA_COLORS } from "../../constants";
import useLoader from "../../util/useLoader";
import useResourceLoader from "../../util/useResourceLoader";
import {LABEL} from '../../LanguageConstant';


function SLASetting(){
    const [resetCt, setResetCt] = useState();
    const [slaSetting, loadingSla, errorLoadingSla, setSlaSetting] = useResourceLoader(
        () => LookupApi.getSLASetting({ ignoreCache: true }),
        [resetCt],
        []
    );

    const [loadingSetSla, errorLoadingSetSla, loadFn] = useLoader();

    const loading = loadingSla || loadingSetSla;
    const error = errorLoadingSla || errorLoadingSetSla;

    function setSlaIdx(idx, value) {
        const N = slaSetting.length;
        const newSla = slaSetting.slice(0);
        value = Math.max(0, value | 0);
        for(let i=0; i < idx; i += 1){ newSla[i] = Math.min(newSla[i], value);}
        newSla[idx] = value;
        for(let i=idx + 1; i < N; i += 1){ newSla[i] = Math.max(newSla[i], value);}

        setSlaSetting(newSla);
    }

    function save(){
        loadFn(async () => {
            SettingsManagementApi.setSLASetting(slaSetting);
            setResetCt(new Date().getTime());
        });
    }

    return loading  ? (
        <Loader/>
    ) : (error ? (
        <Notification error={error} />
    ) : (<>
        <Table style={{width: '24rem'}}>
            <thead>
                <tr>
                    <th>Nivel</th>
                    <th colSpan="3">Número de solicitudes</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Badge color={SLA_COLORS[0]} >&nbsp;&nbsp;</Badge></td>
                    <td>0</td>
                    <td>-</td>
                    <td>{slaSetting[0]}</td>
                </tr>
                {slaSetting.map((_, idx) => (<tr key={idx}>
                    <td><Badge color={SLA_COLORS[idx + 1]}>&nbsp;&nbsp;</Badge></td>
                    <td><Input type="number" value={slaSetting[idx]} onChange={({target:{value}}) => setSlaIdx(idx, value)}/></td>
                    <td>-</td>
                    <td>{idx === slaSetting.length - 1 ? (<>&infin;</>) : slaSetting[idx + 1]}</td>
                </tr>))}
            </tbody>
        </Table>
        <Button color="primary" onClick={save}>{LABEL.save}</Button>
    </>));
}


export default SLASetting;