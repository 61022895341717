import React, { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom';

import AuthApi from '../api/AuthApi';
import Loader from '../components/Loader';
import useLoader from '../util/useLoader';
import UWEEntityApi from '../api/UWEEntityApi';

export const AuthContext = React.createContext();


const UserStore = ({
    KEY: 'user',
    user: null,
    init() {
        const json = localStorage.getItem(this.KEY);
        this.user = json ? JSON.parse(json) : null;
        return this;
    },
    buildUserFrom(user, token, logOutUrl, actualUser) {
        return {
            email: user.email,
            token,
            logOutUrl,
            user,
            actualUser,
            permissions: user.accessProfile.permissions.reduce((_, p) => {
                _[p] = 1;
                return _;
            }, {}),
            roles: user.roles.reduce((_, r) => {
                _[r.role.name] = r;
                return _;
            }, {}),
        };
    },
    setUser(newUser) {
        this.user = newUser

        if (newUser) {
            localStorage.setItem(this.KEY, JSON.stringify(newUser));
        } else {
            localStorage.removeItem(this.KEY);
        }
    },
    isAdmin: false
}).init();


function _AuthProvider(props) {
    const { children } = props;
    const [loading, errorLoading, loadFn] = useLoader(true);
    const [updateCt, setUpdateCt] = useState(0);

    const value = useMemo(() => ({
        user: UserStore.user,
        updateCt,

        async login({ email, password }) {
            const response = await AuthApi.login({ email, password });
            const { token, logOutUrl, user, } = response;
            UserStore.setUser(UserStore.buildUserFrom(user, token, logOutUrl));
            setUpdateCt(updateCt + 1);
        },

        async loginWithToken({ token }) {
            const response = await AuthApi.loginWithToken({ token });
            const { token: authToken, logOutUrl, user } = response;
            UserStore.setUser(UserStore.buildUserFrom(user, authToken, logOutUrl));
            setUpdateCt(updateCt + 1);
        },

        logout() {
            const logOutUrl = UserStore?.user?.logOutUrl;
            UserStore.setUser();
            if (logOutUrl) {
                document.location = logOutUrl;
            } else {
                setUpdateCt(updateCt + 1);
            }
        },

        setCompletedProfile(hasCompletedProfile) {
            UserStore.user.user.hasCompletedProfile = hasCompletedProfile;
            UserStore.setUser(UserStore.user);
        },

        setCompletedVolunteerProfile(user) {
            UserStore.user.user = user;
            UserStore.setUser(UserStore.user);
        },

        async impersonate({ userId }) {
            const actualUser = UserStore.user;
            const response = await AuthApi.impersonate({ userId });
            const { token, logOutUrl, user } = response;
            UserStore.setUser(UserStore.buildUserFrom(user, token, logOutUrl, actualUser));
            setUpdateCt(updateCt + 1);
        },

        async stopImpersonation() {
            const { actualUser } = UserStore.user;
            if (actualUser) {
                UserStore.setUser(actualUser);
                setUpdateCt(updateCt + 1);
            }
        },

        async verifyToken() {
            console.log("verifying user token...")
            try {
                const response = await AuthApi.verifyToken();
                if (!response.data) {
                    console.log("token is invalid or stale, user is not logged in.");
                    UserStore.setUser();
                    setUpdateCt(updateCt + 1);
                } else {
                    console.log("user is still logged in.");
                }
            } catch (e) {
                console.log("error while verifiyng token, user is not logged in.");
                UserStore.setUser();
                setUpdateCt(updateCt + 1);
            }
        },

        isAdmin: UserStore.user?.user?.role?.name === 'administrator'
    }), [UserStore, updateCt]);


    useEffect(() => {
        loadFn(() => value.verifyToken());
    }, []);


    return (
        <AuthContext.Provider value={value}>
            {loading ? <Loader fullscreen /> : children}
        </AuthContext.Provider>
    )
}


export function getToken() {
    return (UserStore.user || {}).token;
}

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = withRouter(_AuthProvider);
