import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { useFormatter } from '../../util/applyFormatting';
import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";


export default function TextArea(props) {
    const {
        id, required, disabled, readonly, autofocus, value, uiSchema, onChange: onChangeForm,
        uiSchema: { 'ui:staticText': staticText, 'ui:classNames': classNames, 'ui:prefix': prefix, 'ui:sufix': sufix, 'ui:placeHolder': placeholder, 'ui:readonlyIf': uiReadonlyIf  },
        schema: { minLength, maxLength },
        formData,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
    } = props;
    const [current, setCurrent] = useState(value);

    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);

    const rows = (uiSchema['ui:options'] || {}).rows || 3;
    useEffect(() => setCurrent(value), [value]);

    
    const defaultValue = "";
    const dataValue = formData || defaultValue;
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const dataPath = useMemo(() => getPathFromId(id), [id]);
    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readonlyif = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, dataPath, {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);
    return (
        <FormGroup>
            <Input
                disabled={disabled}
                type="textarea"
                className="custom"
                value={current || ""}
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly || readonlyif}
                autoFocus={autofocus}
                id={id}
                rows={rows}
            />
        </FormGroup>
    );
}