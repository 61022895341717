import { useContext, useEffect, useMemo } from "react";
import { Redirect, useRouteMatch } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import DashboardApi from "../../api/DashboardApi";
import Dashboard from "../../components/Dashboard/index";
import InternalLayout from "../../components/layouts/InternalLayout";
import Loader from "../../components/Loader";
import UWEContext from "../../context/UWEContext";
import useResourceLoader from "../../util/useResourceLoader";
import UWEEntityApi from "../../api/UWEEntityApi";

function TrayView() {
  const auth = useContext(AuthContext);

  const {
    params: { slug }
  } = useRouteMatch();

  const { dashboards } = useContext(UWEContext);

  const [
    workflowInfo,
    loadingWorkflow,
    errorLoadingWorkflow
  ] = useResourceLoader(() => UWEEntityApi.getWorkflowInfoForSlug({ slug }), [
    slug
  ]);

  const dashboardId = useMemo(() => {
    let dashboardId = dashboards?.filter(db => db.slug === slug).pop()?.id;
    if (slug === "home" && dashboardId === undefined) {
      dashboardId = dashboards[0]?.id;
    }
    return dashboardId;
  }, [dashboards, slug]);

  const [
    dashboard,
    loadingDashboard,
    errorLoadingDashboard
  ] = useResourceLoader(
    async () => (dashboardId ? DashboardApi.get({ id: dashboardId }) : null),
    [dashboardId]
  );

  const TrayComponent = useMemo(() => {
    if (!dashboard) return null;

    const schema = {
      wrapperType: "internal",
      ...dashboard,
      schema: dashboard.definition
    };

    return Dashboard.HOC(schema);
  }, [auth, dashboard]);

  const shouldRedirect = !!workflowInfo?.redirect;
  const isLoading = loadingDashboard || loadingWorkflow;

  return isLoading ? (
    <InternalLayout>
      <Loader />
    </InternalLayout>
  ) : shouldRedirect ? (
    <Redirect to={workflowInfo.redirect} />
  ) : TrayComponent ? (
    <TrayComponent />
  ) : (
    <ErrorTray />
  );
}

const ErrorTray = Dashboard.HOC({
  wrapperType: "internal",
  schema: {
    type: "dashboard",
    elements: [
      {
        type: "text",
        label: "Tray not Found"
      }
    ]
  }
});

export default TrayView;
