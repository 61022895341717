import JsonView from "@uiw/react-json-view";
import React, { useState } from "react";
import { Button, Input, Nav, NavItem, NavLink, TabContent } from "reactstrap";

export const TABS = {
    'prompt': { title: 'Message' },
    'messages': { title: 'History' },
}

export const extractJsonFromText = (text) => {
    const regex = /({.*})/s;
    const match = text.match(regex);
    try {
        return match ? JSON.parse(match[0]) : null;
    } catch (e) {
        console.error('Error parsing JSON:', e);
        return null;
    }
};

export const formatTextWithLineBreaks = (text) => {
    const trimmedText = text.startsWith('\n') ? text.substring(1) : text;
    return trimmedText.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
};

export const buildTextArray = (text, json) => {
    if (!json) return [{ type: 'text', value: text }];

    const jsonString = JSON.stringify(json, null, 2);
    const parts = [];

    const beforeJson = text.split(/{.*}/s)[0];
    const afterJson = text.split(/{.*}/s)[1];

    if (beforeJson) parts.push({ type: 'text', value: beforeJson });
    if (jsonString) parts.push({ type: 'json', value: json });
    if (afterJson) parts.push({ type: 'text', value: afterJson });

    return parts;
};

export const correctJson = (jsonString) => {
    jsonString = jsonString.replace(/\/\/[^\n]*\n/g, '');
    jsonString = jsonString.replace(/,\s*}/g, '}');
    jsonString = jsonString.replace(/,\s*]/g, ']');
    return jsonString;
}

export const PromptAndHistory = (props) => {

    const { onConfirm, messages, prompt, setPrompt } = props;

    const [tab, setTab] = useState("prompt");

    return (
        <>
            <Nav tabs>{Object.entries(TABS).map(([key, { title }]) => (
                <NavItem key={key} onClick={() => setTab(key)}>
                    <NavLink active={tab === key}>{title}</NavLink>
                </NavItem>
            ))}</Nav>
            <TabContent activeTab={tab}>
                {tab === "prompt" ? <>
                    <Input type="textarea" value={prompt} onChange={(e) => setPrompt(e.target.value)} rows={10} />
                    <div className="d-flex justify-content-end mt-2">
                        <Button color="primary" onClick={() => onConfirm()}>
                            Confirmar
                        </Button>
                    </div>
                </> : null}
                {tab === "messages" ? <>
                    {(messages || []).map((obj, key) => {
                        if (key === 0) return null;
                        const json = extractJsonFromText(obj.content);
                        const parts = buildTextArray(obj.content, json);
                        return (
                            <>
                                <label style={{ background: obj.role === 'system' ? '#f9f9fa' : '', fontWeight: 'bold', fontSize: 13, color: obj.role === 'user' ? '#060692' : '#dd3b0b', marginBottom: 0 }}>{obj.role}</label>
                                {(parts || []).map((p, k) => {
                                    if (p.type === "json") {
                                        return <JsonView value={p.value} />
                                    } else {
                                        return <div style={{ background: obj.role === 'system' ? '#f9f9fa' : '', fontSize: 13, display: 'block', marginBottom: 10 }}>{formatTextWithLineBreaks(p.value)}</div>
                                    }
                                })}
                            </>
                        )
                    })}
                </> : null}
            </TabContent>
        </>);
}
