import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FormGroup } from "reactstrap";
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";

export default function PhoneInput({
    id,
    schema,
    uiSchema: {
        'akc:requiredIfVisible': akcRequiredIfVisible,
        'ui:readonly': uiReadonly,
        'ui:readonlyIf': uiReadonlyIf,
        'ui:onSelect': onSelectExpr,
    },
    value,
    placeholder,
    schema: {
        title
    },
    required,
    disabled,
    readonly,
    autofocus,
    onChange: onChangeForm,
    onKeyChange,
    onBlur,
    onFocus,
}) {
    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);
    useEffect(() => setCurrent(value), [value]);
    required = akcRequiredIfVisible || required;
    return (
        <FormGroup>
            {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label> : null}
            <InputMask
                mask='(999)999-9999'
                disabled={disabled}
                className="form-control"
                value={current || ""}
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly}
                autoFocus={autofocus}
                id={id}
            />
        </FormGroup>
    );
}