import fetchApi from "../util/fetchApi";
import ApiDefinition from "./ApiDefinition";
import checkErrors from './checkErrors';


const OpenAIApi = new ApiDefinition({
    generateWorkflow: {
        url: 'open-ai/generateWorkflow/',
        method: 'post',
        body: data => data
    },
    generateForm: {
        url: 'open-ai/generateForm/',
        method: 'post',
        body: data => data
    }
})

export default OpenAIApi;