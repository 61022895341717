import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { json } from "@codemirror/lang-json";
import JnxEditor from "../../JnxEditor/JnxEditor";
import CollapsableInputGroup from "../../CollapsableInputGroup";
import useLoader from "../../../util/useLoader";
import Loader from "../../Loader";
import Notification from "../../Notification";


const PATTERN = { type: "jnx" };

function JnxField({ fieldProps: prop, value, object, onChange }) {
    const { label, test } = prop;

    const [param, setParam] = useState();
    const [testing, testError, testFn] = useLoader();
    const [testResult, setTestResult] = useState();
    const [showModal, setShowModal] = useState();

    const performTest = useCallback(() => {
        setTestResult();
        if (test?.onTest) testFn(async () => {
            const data = await test.onTest({ param, object });
            setTestResult(JSON.stringify(data, null, 4))
        });
    }, [test, param, object]);

    const inputs = useMemo(() => <>
        {test ? (<CollapsableInputGroup title="Test" icon="fa fa-flask" >
            <Input value={param} placeholder={test.param} style={{maxWidth: '10em'}} onChange={({target: {value}}) => setParam(value)}/>
            <InputGroupAddon addonType="prepend">
                <Button onClick={() => performTest()} disabled={testing} >
                    Test
                </Button>
            </InputGroupAddon>
        </CollapsableInputGroup>) : null}
        <InputGroupAddon addonType="prepend">
            <Button outline={!showModal} onClick={() => setShowModal(!showModal)} title="Open in Modal"> 
                <i className="fa fa-window-restore" />
            </Button>
        </InputGroupAddon>
    </>, [test, param, setParam, performTest, testing, showModal, setShowModal]);

    const makeComponent = ({modal} = {}) => (<>
        {showModal ? null : <label className="title">{label}</label>}
        <JnxEditor value={value} onChange={onChange} inputs={inputs}
            maxHeight={
                modal ? (
                    testing || testError || testResult ? "36vh" : "72vh"
                ): "15em"
            } />
        {testing ? (
            <Loader />
        ) : (testError ? (<>
            <div>Results</div>
            <Notification error={testError} />
        </>) : (testResult ? <>
            <div>Results</div>
            <CodeMirror
                readOnly
                value={testResult} 
                width="100%"
                maxHeight="30vh"
                extensions={[json()]}
            />
        </> : null))}
    </>);
    
    return <>
        {makeComponent()}
        {showModal ? (
        <Modal isOpen container="body"
            className="modal-primary maximized"
            toggle={() => setShowModal(false)}
            backdrop
        >
            <ModalHeader
                toggle={() => setShowModal(false)}
            >
                {label}
            </ModalHeader>
            <ModalBody>
                {makeComponent({modal: true})}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setShowModal(false)}>Close Modal</Button>
            </ModalFooter>
        </Modal>
        ) : null}
    </>
}



JnxField.pattern = PATTERN;


export default JnxField;