// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect } from 'react-router';
import IntegrationsApi from "../../../api/IntegrationsApi";
import FormComponent from '../../../components/FormComponent';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { EditIntegrationForm } from "./EditIntegration";

const CreateIntegration = FormComponent({
    ...EditIntegrationForm.formDefinition,
    displayName: 'Nueva Integración',
    schema: {
        ...EditIntegrationForm.formDefinition.schema,
        title: "Nueva Integración",
    },
    submitButtons: {
        submit: {text: "Crear Integración", className: "btn-primary"},
    },
    async onSubmit({ object }) {
        await IntegrationsApi.createIntegration(object);
    },
    loadData: undefined,
    renderFormSubmitted: () => (
        <Redirect to="/system-settings/integrations" />
    )
});

export default function () {
    return <InternalLayout><CreateIntegration /></InternalLayout>;
};