import React, { useState, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import leftPad from '../../util/leftPad';
import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";

export default function TimeInputField(props) {
    const {
        id, required, disabled, readonly, autofocus, uiSchema, onChange: onChangeForm,
        uiSchema: { 'ui:staticText': staticText, 'ui:classNames': classNames, 'ui:prefix': prefix, 'ui:sufix': sufix, 'ui:placeHolder': placeholder, 'ui:readonlyIf': uiReadonlyIf  },
        schema: { title, minLength, maxLength },
        formData,
        formData: value,
        idSchema: { $id } = {},
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        onChange,
    } = props;
    const [current, setCurrent] = useState(value);

    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);

    const rows = (uiSchema['ui:options'] || {}).rows || 3;
    useEffect(() => setCurrent(value), [value]);

    
    const defaultValue = "";
    const dataValue = formData || defaultValue;
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const dataPath = useMemo(() => getPathFromId($id), [$id]);
    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readonlyif = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, dataPath, {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);

    const [valid, hour, minute, ampm] = useMemo(() => {
        const m = /^(\d\d?):(\d\d?)\s*(am|pm)$/.exec((value || '').toLowerCase());
        if (m) {
            return [
                true,
                Math.min(Math.max(m[1] | 0, 0), 12) || 12,
                Math.min(Math.max(m[2] | 0, 0), 59),
                m[3]
            ]
        }

        return [false, null, null, null];

    }, [value]);

    const setTime = useMemo(() => _.debounce((hour, minute, ampm) => {
        hour = Math.min(Math.max(hour, 0), 12) || 12;
        minute = Math.min(Math.max(minute, 0), 59);
        const value = `${leftPad(hour || 12, 2, '0')}:${leftPad(minute || 0, 2, '0')}${ampm ?? 'am'}`;
        console.log("new value", value);
        return onChange(value);
    }, 100), [onChange]);

    const [
        onHourChange,
        onMinuteChange,
        onAMPMChange,
    ] = useMemo(() => {
        return [
            ({target: {value}}) => setTime(value | 0, minute, ampm),
            ({target: {value}}) => setTime(hour, value | 0, ampm),
            ({target: {value}}) => setTime(hour, minute, value),
        ]
    }, [setTime, hour, minute, ampm])

    const readOnlyIf = false;
    const isDisabled = readonly || readonlyif || disabled;
    const allowNoValue = false;

    return (
        <FormGroup disabled={readonly || readOnlyIf || disabled}>
            {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label> : null}
            <InputGroup style={{width:'16em'}}>
                <Input placeholder='--'  value={valid ? leftPad(hour, 2, '0') : ""} disabled={isDisabled} required={required} onChange={onHourChange} />
                <InputGroupText>:</InputGroupText>
                <Input placeholder='--' value={valid ? leftPad(minute, 2, '0') : ""} disabled={isDisabled} required={required} onChange={onMinuteChange} />
                <Input type="select" disabled={isDisabled} required={required} value={ampm ?? ""} onChange={onAMPMChange}>
                    {allowNoValue || !valid ? <option value="">---</option> : null}
                    <option value="am">am</option>
                    <option value="pm">pm</option>
                </Input>
            </InputGroup>
        </FormGroup>
    );
}