import React, { useState, useCallback, useRef } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";

import { ExtendedForm } from "../FormComponent";

const SearchBar = ({
    resource,
    formDefinition,
    filters,
    onSearch,
    onClearSearch,
    auth
}) => {
    const [resetCt, setResetCt] = useState(0);
    const ref = useRef();

    function clearSearch(e) {
        e.preventDefault();
        setResetCt(new Date().getTime());
        onClearSearch();
    }

    const onSubmit = useCallback(({ formData }) => {
        onSearch(formData);
        setResetCt(new Date().getTime());
    }, [onSearch])

    return (<Card className="search-bar" onClick={e => {
        e.stopPropagation();
    }}>
        {resource ? <CardHeader>Filtrar {resource}</CardHeader> : null}
        <CardBody>
            <ExtendedForm
                tagName="div"
                ref={ref}
                key={resetCt}
                formContext={{
                    auth
                }}
                formData={filters}
                {...formDefinition}
                onSubmit={onSubmit}
            >
                <Row>
                    <Col>
                        <Button color="primary" type="submit" onClick={e => {
                            onSubmit(ref.current.state);
                        }}>Filtrar</Button>
                        {" "}
                        <Button color="secondary" onClick={clearSearch}>Limpiar Filtro</Button>
                    </Col>
                </Row>
            </ExtendedForm>
        </CardBody>
    </Card>);
}

export default SearchBar;