import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Button, Table } from "reactstrap";
import { concatenatePaths, getObject, mapObject, invertMap } from "../../../util/mapObject";
import { useSideChannelSubscription } from "../../../util/useSideChannel";
import Notification from "../../Notification";
import getPathFromId from "../../../util/getPathFromId";
import { HEADER_COMPONENTS } from "../headers";
import { useJnx } from "../../../util/jnx";

import MakeArrayItem from "./MakeArrayItem";
import ArrayFieldDescription from "./ArrayFieldDescription";
import ArrayFieldTitle from "./ArrayFieldTitle";
import useCompiledPerRowConditionals from "./useCompiledPerRowConditionals";
import useOptionsLookup from "../hooks/useOptionsLookup";
import parseObjectMapFromSchema from "../../../util/parseObjectMapFromSchema";
import Loader from "../../Loader";
import useTableLayoutType from "./useTableLayoutType";


function ArrayFieldTemplate(props) {
    const {
        $id,
        layout,
        filterRowJnx,
        formContext,
        hasAddBtn,
        headerOnEmpty,
        hideHeader,
        uiSectionType,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        jnxOnAddAction,
        loading, errorLoading,
        objectItemsSchema,
        onEmptyMessage,
        panels,
        perRowClassNamesIf,
        perRowShowColumnsIf,
        readonlyRowIfJnx,
        rootFormData,
        rowClassNames,
        expandable, expanded, toggleExpand,
        hideTitle,
        additionalButtons,
        selectedItem,
        setSelectedItem
    } = useArrayFieldTemplateHooks(props);


    return (<fieldset className={props.className} id={props.idSchema.$id}>

        {!hideTitle ?
            <ArrayFieldTitle
                key={`array-field-title-${props.idSchema.$id}`}
                expandable={expandable}
                expanded={expanded} toggleExpand={toggleExpand}
                idSchema={props.idSchema}
                sectionType={uiSectionType}
                title={props.uiSchema["ui:title"] || props.title}
                required={props.required}
            /> : null}

        {(props.uiSchema["ui:description"] || props.schema.description) && (
            <ArrayFieldDescription
                key={`array-field-description-${props.idSchema.$id}`}
                DescriptionField={props.DescriptionField}
                idSchema={props.idSchema}
                description={
                    props.uiSchema["ui:description"] || props.schema.description
                }
            />
        )}
        {expanded ? (<div>
            {layout.wrapArray(<>
                {((props.items && props.items.length) || headerOnEmpty) && headers.length && !hideHeader && layout.makeHeaders ? layout.makeHeaders() : null}
                {loading ? layout.wrapBody(layout.wrapLoader(<Loader />)) : (<>
                    {errorLoading ? layout.wrapBody(<>
                        <Notification error={errorLoading} />
                    </>) : null}
                    {layout.wrapBody(<>
                        {props?.items?.length ? props.items.map((p, rowIdx) => <MakeArrayItem
                            key={rowIdx}
                            $id={$id}
                            props={p}
                            onAddClick={jnxOnAddAction || props.onAddClick}
                            layout={layout}
                            rowIdx={rowIdx}
                            rowCount={props.items.length}
                            formContext={formContext}
                            rootFormData={rootFormData}
                            filterRowJnx={filterRowJnx}
                            readonlyRowIfJnx={readonlyRowIfJnx}
                            itemsAreObjectType={itemsAreObjectType}
                            objectItemsSchema={objectItemsSchema}
                            panels={panels}
                            itemsUiSchema={itemsUiSchema}
                            showColumnsIf={perRowShowColumnsIf}
                            rowClassNames={rowClassNames}
                            rowClassNamesIf={perRowClassNamesIf}
                            additionalButtons={additionalButtons}
                        />) : (onEmptyMessage ? layout.makeEmptyMessage() : null)}
                        {hasAddBtn ? layout.wrapAddButton(
                            <Button
                                className="array-item-add"
                                color="primary"
                                onClick={jnxOnAddAction || props.onAddClick}
                                disabled={props.disabled || props.readonly}
                            >
                                <i className="fa fa-plus" />
                                {itemsUiSchema["ui:addButtonText"] ? ` ${itemsUiSchema["ui:addButtonText"]}` : null}
                            </Button>
                        ) : null}
                    </>)}
                </>)}
            </>, {
                selectedItem,
                $id,
                rootFormData,
                formContext,
                setSelectedItem,
                onAddClick: jnxOnAddAction || props.onAddClick,
                props
            })}
        </div>) : null}
    </fieldset>);
}

function useArrayFieldTemplateHooks(props) {
    const {
        formContext,
        schema,
        idSchema: { $id },
        uiSchema = {},
        formData,
    } = props;

    const {
        "ui:showheaderOnEmpty": headerOnEmpty,
        "ui:addAction": addAction,
        'ui:expandable': expandable,
        'ui:expandedDefault': expandedDefault = true,
        "ui:onEmptyMessage": onEmptyMessage,
        "ui:arrayType": arrayType = "table",
        "ui:onDataChanged": onDataChanged,
        "ui:hideTitle": hideTitle,
        "ui:hideHeader": hideHeader,
        'ui:sectionType': uiSectionType,
        items: itemsUiSchema = {}
    } = uiSchema;

    const [expanded, setExpanded] = useState(expandedDefault);
    const [selectedItem, setSelectedItem] = useState(0);
    function toggleExpand(e) {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(!expanded);
    }

    const { sideChannel, openModal } = formContext;
    const rootFormData = useSideChannelSubscription(sideChannel, 0) || {};
    const { setFormDataValues } = formContext;
    const {
        "ui:filterRow": filterRow,
        "ui:readonlyRowIf": readonlyRowIf,
    } = itemsUiSchema;
    const {
        items: {
            required: requiredColumns = [],
            lookup,
            additionalButtons
        },

    } = schema;

    const { options, loadingOptions, errorLoadingOptions } = useOptionsLookup({ lookup, rootFormData, formContext });

    useEffect(() => {
        if (!formData?.length && options && Array.isArray(options)) {
            const path = getPathFromId($id);
            const dataMap = parseObjectMapFromSchema(schema.items, null, { removeNullMappedFields: true });
            // console.log("test",options,dataMap, mapObject(options, invertMap(dataMap)))
            const invertDataMap = invertMap(dataMap);
            setFormDataValues({
                [path]: options.map(x => mapObject(x, invertDataMap))
            });
        }
    }, [options, formData, $id, schema]);

    const arrayRoute = useMemo(() => concatenatePaths(getPathFromId($id), ".."), [$id]);

    const addItems = useCallback((items) => {
        const path = getPathFromId($id);
        setFormDataValues({
            [path]: [...(formData || []), ...(items || [])]
        });
    }, [$id, formData]);

    const jnxOnAddAction = useJnx(addAction, ([event], addActionJnx) => {
        event.stopPropagation();
        addActionJnx.evalAsync(rootFormData, '', {
            openModal,
            formContext,
        }).then(result => {
            if (Array.isArray(result) && result.length > 0) {
                addItems(result);
            }
        });
    }, [openModal, addAction, formData, rootFormData]);


    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
    }), [setFormDataValues]);

    const onDataChangedJnx = useJnx(onDataChanged, { functionBinds }, [functionBinds]);
    const onDataChangedfnRef = useRef();

    const path = useMemo(() => getPathFromId($id), [$id]);
    const formData2 = useMemo(() => getObject(rootFormData, path), [rootFormData, path]);

    onDataChangedfnRef.current = useCallback(() => {
        onDataChangedJnx.evalAsync(rootFormData, path, {
            openModal,
            formContext,
        });
    }, [$id, rootFormData, openModal, formContext]);

    useEffect(() => {
        if (onDataChangedJnx) {
            onDataChangedfnRef.current();
        }
    }, [formData2]);

    const filterRowJnx = useJnx(filterRow);
    const readonlyRowIfJnx = useJnx(readonlyRowIf);

    const [
        showColumnsIf,
        perRowShowColumnsIf,
    ] = useCompiledPerRowConditionals(itemsUiSchema["ui:showColumnsIf"]);

    const [
        classNamesIf,
        perRowClassNamesIf,
    ] = useCompiledPerRowConditionals(itemsUiSchema["ui:classNamesIf"]);


    const itemsAreObjectType = schema.type === "array" && schema.items.type === "object" && schema.items.type && !(uiSchema?.items?.['ui:field']);
    const rowClassNames = useMemo(() => (classNamesIf ? Object.entries(classNamesIf).map(
        ([className, conditional]) => conditional.eval(rootFormData, arrayRoute) ? className : null
    ).filter(x => !!x).join(' ') : ''), [classNamesIf, rootFormData]);

    const rowProperties = useMemo(() => Object.entries(schema?.items?.properties || {}).reduce((_, [k, v]) => {
        const showIf = showColumnsIf[k];
        if (showIf && !showIf.eval(rootFormData, arrayRoute, {
            formContext
        })) {
            return _;
        }
        if (itemsUiSchema[k] && itemsUiSchema[k]["ui:newRow"]) {
            _.panels.push({ field: k, schema: v });
        } else {
            _.primary[k] = v;
        }
        return _;
    }, {
        primary: {},
        panels: []
    }), [schema?.items?.properties, rootFormData]);

    const headers = useMemo(() => Object.entries(rowProperties.primary || {}).map(([k, v]) => {
        const {
            "ui:noColumnTitle": noColumnTitle,
            "ui:columnHeader": columnHeader,
        } = itemsUiSchema[k] || {}

        if (columnHeader) {
            const Component = HEADER_COMPONENTS[columnHeader];
            if (Component) return { field: k, Component };
        }
        if (noColumnTitle) return { field: k, text: "" };

        const required = requiredColumns.indexOf(k) >= 0;

        return { field: k, text: `${v?.title || k}${required ? '*' : ''}` };
    }), [rowProperties.primary])

    const objectItemsSchema = useMemo(() => (!itemsAreObjectType ? {} : {
        ...(schema.items || {}),
        properties: Object.entries(rowProperties.primary).reduce((_, [k, v]) => {
            v = { ...v };
            if (!(itemsUiSchema[k] || {})["ui:noColumnTitle"] && arrayType === 'table') {
                v.title = " ";
            }
            _[k] = v;
            return _;
        }, {})
    }), [itemsAreObjectType, rowProperties.primary, arrayType]);

    const panels = rowProperties.panels;

    const hasAddBtn = props.canAdd && (itemsUiSchema['ui:addable'] !== false);
    const hasAddBtnInRow = props.canAdd && (itemsUiSchema['ui:addableFromRow'] === true);
    const {
        "ui:tabNameExpr": tabNameExpr,
        "ui:orderable": orderable,
        "ui:removable": removable,
        "ui:positionButtons": positionButtons
    } = itemsUiSchema || {};
    const hasMoveBtns = (orderable !== false) && (props.hasMoveUp || props.hasMoveDown);
    const hasRemoveBtn = removable !== false && props.hasRemove;
    const hasToolbar = (props.items[0] || {}).hasToolbar && (hasMoveBtns || hasRemoveBtn)

    const layout = useTableLayoutType({
        $id,
        arrayType,
        tabNameExpr,
        hasAddBtnInRow,
        hasToolbar,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        onEmptyMessage,
        positionButtons,
        props,
        rowClassNames,
    });

    return {
        $id,
        layout,
        filterRowJnx,
        formContext,
        hasAddBtn,
        hasAddBtnInRow,
        hasToolbar,
        headerOnEmpty,
        hideHeader,
        uiSectionType,
        headers,
        loading: loadingOptions,
        errorLoading: errorLoadingOptions,
        itemsAreObjectType,
        itemsUiSchema,
        jnxOnAddAction,
        objectItemsSchema,
        onEmptyMessage,
        panels,
        perRowClassNamesIf,
        perRowShowColumnsIf,
        readonlyRowIfJnx,
        rootFormData,
        rowClassNames,
        expandable, expanded, toggleExpand,
        hideTitle,
        additionalButtons,
        selectedItem,
        setSelectedItem
    };

}



export default ArrayFieldTemplate;