import React, { useMemo } from "react";
import { interpolate } from "../../util/mapObject";
import { ENDPOINT } from "../../constants";


function Link(props) {
    const {
        schema: {
            title,
        },
        uiSchema: {
            'ui:href': href,
            'ui:mt': mt = 35,
        },
    } = props;

    const link = useMemo(() => {

        const scope = {
            ENDPOINT,
        };

        return interpolate(href, scope);

    }, [href]);


    return (
        <a style={{ marginTop: mt }} href={link} target="_blank" rel="noreferrer" className="btn btn-primary">{title}</a>
    );
}


export default Link;