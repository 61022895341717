import React from 'react';
import { Card, Alert, CardBody, CardHeader, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { useHistory } from 'react-router';

import useResourceLoader from "../util/useResourceLoader";
import useTitle from "../util/useTitle";
import Loader from '../components/Loader';
import ManageFaqApi from '../api/ManageFaqApi';
import InternalLayout from '../components/layouts/InternalLayout';
import Notification from '../components/Notification';
import WorkTray from "../components/Dashboard/WorkTray";
import CollapsibleCard from '../components/CollapsibleCard';


const DOCUMENTS = [
    {},
];


const VIDEOS = [
    { title: "Video ayuda - Registro - Voluntarios", url: "https://player.vimeo.com/video/999659317?h=8ee78ca9e7" },
    { title: "Video ayuda - Ejecución de eventos - Voluntarios", url: "https://player.vimeo.com/video/999659773?h=c5c2cdaf8e" },
    { title: "Video ayuda - Planificación de eventos - Voluntarios", url: "https://player.vimeo.com/video/999659832?h=e17f7ba25d" },
    { title: "Video ayuda - Certificaciones - Voluntarios", url: "https://player.vimeo.com/video/999659698?h=2b9785c352" },
];
const dashboardWorktray = {
  "element": {
      "type": "worktray",
      "colSpan": 4,
      "title": " ",
      "entityType": "HelpDeskTicket",
      "resource": "uwe-entities/objectSchema/HelpDeskTicket",
      "lookupParams": {},
      "tableActions": [
          {
              "label": "Crear Ticket",
              "backgroundColor": "#00AFB9",
              "route": "/workflow/ticketing/new"
          }
      ],
      "defaultFilter": "-date",
      "filters": [
          {
              "title": "Nombre",
              "key": "metadata-like-name",
              "type": "string"
          },
          {
              "title": "Fecha Desde",
              "key": "after",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Fecha Hasta",
              "key": "before",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Etapa",
              "key": "stage",
              "type": "string"
          },
          {
              "title": "Problema",
              "key": "metadata-like-subject",
              "type": "string"
          },
          {
              "title": "Estatus",
              "key": "metadata-like-ticketStatus",
              "type": "string"
          },
          {
              "title": "Mostrar Solicitudes Cerradas",
              "key": "isClosed",
              "type": "boolean"
          },
          
          {
              "title": "Mostrar Solicitudes Archivadas",
              "key": "isArchived",
              "type": "boolean"
          }
      ],
      "columns": [
          {
              "title": "Solicitud",
              "sortkey": "metadata.application",
              "map:field": "metadata.application"
          },
          {
              "title": "Fecha",
              "sortkey": "createdAt",
              "map:field": {
                  "expr": "$onlyDate(createdAt)"
              },
              "format": ""
          },
          {
              "title": "Nombre",
              "sortkey": "metadata.name",
              "map:field": "metadata.name"
          },
          {
              "title": "Etapa",
              "sortkey": "status.0.name",
              "map:field": "status.0.name"
          },
          {
              "title": "Problema",
              "sortkey": "metadata.subject",
              "map:field": "metadata.subject"
          },
          {
              "title": "Estatus",
              "sortkey": "metadata.ticketStatus",
              "map:field": "metadata.ticketStatus"
          },
          {
              "title": "Días Abierto",
              "sortkey": "metadata.application",
              "map:field": {
                  "expr": "$getBusinessDatesCount(lastUpdated, $now())"
              }
          },
        
      ]
  },
  "history": {
      "length": 3,
      "action": "PUSH",
      "location": {
          "pathname": "/workflow/ticketing/tray",
          "search": "",
          "hash": "",
          "key": "l9s23h"
      }
  },
  "scope": {
      "dashboard": {
          "wrapperType": "internal",
          "id": 11,
          "order": 3,
          "slug": "ticketing",
          "name": " ",
          "image": {
              "icons": {
                  "base": "dimo-icon dimo-icon-v_uso",
                  "paths": [
                      "p1"
                  ]
              }
          },
          "definition": {
              "type": "dashboard",
              "elements": [
                  {
                      "type": "worktray",
                      "colSpan": 4,
                      "title": " ",
                      "entityType": "HelpDeskTicket",
                      "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                      "lookupParams": {},
                      "tableActions": [
                          {
                              "label": "Crear Ticket",
                              "backgroundColor": "#00AFB9",
                              "route": "/workflow/ticketing/new"
                          }
                      ],
                      "defaultFilter": "-date",
                      "filters": [
          {
              "title": "Nombre",
              "key": "metadata-like-name",
              "type": "string"
          },
          {
              "title": "Fecha Desde",
              "key": "after",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Fecha Hasta",
              "key": "before",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Etapa",
              "key": "stage",
              "type": "string"
          },
          {
              "title": "Problema",
              "key": "metadata-like-subject",
              "type": "string"
          },
          {
              "title": "Estatus",
              "key": "metadata-like-ticketStatus",
              "type": "string"
          },
          {
              "title": "Mostrar Solicitudes Cerradas",
              "key": "isClosed",
              "type": "boolean"
          },
          {
              "title": "Mostrar Solicitudes Archivadas",
              "key": "isArchived",
              "type": "boolean"
          }
      ],
      "columns": [
          {
              "title": "Solicitud",
              "sortkey": "metadata.application",
              "map:field": "metadata.application"
          },
          {
              "title": "Fecha",
              "sortkey": "createdAt",
              "map:field": {
                  "expr": "$onlyDate(createdAt)"
              },
              "format": ""
          },
          {
              "title": "Etapa",
              "sortkey": "status.0.name",
              "map:field": "status.0.name"
          },
          {
              "title": "Problema",
              "sortkey": "metadata.subject",
              "map:field": "metadata.subject"
          },
          {
              "title": "Estatus",
              "sortkey": "metadata.ticketStatus",
              "map:field": "metadata.ticketStatus"
          },
          {
              "title": "Días Abierto",
              "sortkey": "metadata.application",
              "map:field": {
                  "expr": "$getBusinessDatesCount(lastUpdated, $now())"
              }
          },
          {
              "title": "Agencia",
              "sortkey": "organizationalUnit.name",
              "map:field": "organizationalUnit.name"
          },
          {
              "title": "Acciones",
              "actions": [
                  {
                      "label": "Trabajar",
                      "backgroundColor": "#10112B",
                      "route": "/workflow/ticketing/$id",
                      "require": [
                          {
                              "flag": "canWork"
                          }
                      ]
                  },
                  {
                      "label": "Ver",
                      "backgroundColor": " #15416F",
                      "route": "/workflow/ticketing/$id/view",
                      "restrict": [
                          {
                              "flag": "canWork"
                          }
                      ]
                  }
              ]
          }
      ]
                  }
              ]
          },
          "version": 20,
          "lastUpdated": "2023-07-10T15:49:27.0562558",
          "schema": {
              "type": "dashboard",
              "elements": [
                  {
                      "type": "worktray",
                      "colSpan": 4,
                      "title": " ",
                      "entityType": "HelpDeskTicket",
                      "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                      "lookupParams": {},
                      "tableActions": [
                          {
                              "label": "Crear Ticket",
                              "backgroundColor": "#00AFB9",
                              "route": "/workflow/ticketing/new"
                          }
                      ],
                      "defaultFilter": "-date",
                      "filters": [
          {
              "title": "Nombre",
              "key": "metadata-like-name",
              "type": "string"
          },
          {
              "title": "Fecha Desde",
              "key": "after",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Fecha Hasta",
              "key": "before",
              "type": "string",
              "format": "date"
          },
          {
              "title": "Etapa",
              "key": "stage",
              "type": "string"
          },
          {
              "title": "Problema",
              "key": "metadata-like-subject",
              "type": "string"
          },
          {
              "title": "Estatus",
              "key": "metadata-like-ticketStatus",
              "type": "string"
          },
          {
              "title": "Mostrar Solicitudes Cerradas",
              "key": "isClosed",
              "type": "boolean"
          }
      ],
      "columns": [
          {
              "title": "Núm. Ticket",
              "sortkey": "metadata.application",
              "map:field": "metadata.application"
          },
          {
              "title": "Fecha",
              "sortkey": "createdAt",
              "map:field": {
                  "expr": "$onlyDate(createdAt)"
              },
              "format": ""
          },
          {
              "title": "Etapa",
              "sortkey": "status.0.name",
              "map:field": "status.0.name"
          },
          {
              "title": "Problema",
              "sortkey": "metadata.subject",
              "map:field": "metadata.subject"
          },
          {
              "title": "Estatus",
              "sortkey": "metadata.ticketStatus",
              "map:field": "metadata.ticketStatus"
          },
          {
              "title": "Días Abierto",
              "sortkey": "metadata.application",
              "map:field": {
                  "expr": "$getBusinessDatesCount(lastUpdated, $now())"
              }
          },
          {
              "title": "Agencia",
              "sortkey": "organizationalUnit.name",
              "map:field": "organizationalUnit.name"
          },
          {
              "title": "Acciones",
              "actions": [
                  {
                      "label": "Trabajar",
                      "backgroundColor": "#10112B",
                      "route": "/workflow/ticketing/$id",
                      "require": [
                          {
                              "flag": "canWork"
                          }
                      ]
                  },
                  {
                      "label": "Ver",
                      "backgroundColor": " #15416F",
                      "route": "/workflow/ticketing/$id/view",
                      "restrict": [
                          {
                              "flag": "canWork"
                          }
                      ]
                  }
              ]
          }
      ]
                  }
              ]
          }
      },
      "dashboardData": {}
  }
}
export function Faq() {
  const history = useHistory();
  useTitle("Ayuda");

  const [listFaqs, loadingFaqs] = useResourceLoader(
    () => ManageFaqApi.getFaqs(),
    [], null);

  return (
    <InternalLayout>
      <div>
        {DOCUMENTS.length || VIDEOS.length ? (<>
        <h1>Documentaci&oacute;n</h1><br />
        <h4>Aquí puedes encontrar los documentos de apoyo para el Comedor de la Kennedy  </h4>
        {/* {DOCUMENTS.length ? (<>
          <h3>Documentos</h3>
          <ListGroup>
            {DOCUMENTS.map(({title, url}, idx) => (<ListGroupItem key={idx}>
              <a href={url} target="_blank" rel="noreferrer">{title}</a>
            </ListGroupItem>))}
          </ListGroup>
          <br />
        </>) : null} */}
        <h3>Videos de tutorial</h3>
        <div className="d-flex flex-wrap flex-row">
          {VIDEOS.map(({title, url}, idx) => (<Card className="mb-4">
            <CardBody key={idx}>
              <h4 className="text-default">{title}</h4>
              <iframe width="560" height="315" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </CardBody>
          </Card>))}
          {!(VIDEOS?.length) ? (
            <Notification color="warning">Al momento no se encuentra material en esta sección. Verifique de nuevo pronto.</Notification>
          ) : ""}
        </div>
        <br />
        </>) : null}
      </div>
      <br/>
      {/* {loadingFaqs ? <Loader /> : (
        listFaqs?.length ?
          listFaqs?.map((faq, key) => (
            <FaqCard key={key} faq={faq} />
          )) :
          <Alert color="info">No faqs have been created yet</Alert>
      )} */}
      
     
      {/* <h3>Crear un Ticket</h3>
      <h4>¿Necesitas ayuda directo al equipo? Crea un ticket.</h4>
      
      {DOCUMENTS.length ? (<>
        <CollapsibleCard title="Tickets">
          <WorkTray element={dashboardWorktray.element} scope={dashboardWorktray.scope}/>
        </CollapsibleCard>
        </>) : null
      } */}
    </InternalLayout>
  )
};

export default Faq;

function FaqCard({
  faq
}) {
  const {
    content,
    createdAt,
  } = faq || {};

  return (
    <Card style={{ padding: 1, marginBlockEnd:20, border: 0, boxShadow: 0, borderBottom: '1.5px solid #ccc' }}>
      <CardHeader>
        <span>{formatDate(createdAt)}</span>
      </CardHeader>
      <CardBody>
        {HTMLCodeDisplay(content)}
      </CardBody>
    </Card>
  );
}

const HTMLCodeDisplay = (htmlCode) => {
  return <div dangerouslySetInnerHTML={{ __html: `<div style="font-family: 'Inter'">${htmlCode}</div>` }} />;
};

const formatDate = (dateStr) => {
  if (!dateStr)
    return "";
  const date = new Date(dateStr);
  const monthAbbr = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const formattedDate = `${monthAbbr}-${date.getDate()}-${date.getFullYear()}`;
  return formattedDate;
}