import { t } from "@lingui/macro";
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const ObjectSchemaEditor = FormComponent({
    ...parseFormDefinition({
        displayName: 'ObjectSchemaEditor',
        title: t`ObjectSchema Details`,
        schemaProps: {
            "title": [t`Object Schema`, " ${name}"].join(''),
            "type": "object",
            "ui:newSection": true,
            ":classNames": "grid-3-columns",
        },
        schema: {
            "info": {
                "title": "",
                ":classNames": "from-col-1-size-3 grid-3-columns",
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Name",
                        "map:field": "name"
                    },
                    "version": {
                        "type": "number",
                        "title": "Version",
                        "ui:readonly": true,
                        "map:field": "version"
                    },
                    "lastUpdated": {
                        "type": "string",
                        "format": "date",
                        "ui:readonly": true,
                        "map:field": "lastUpdated"
                    }
                }
            },
            "schema": {
                "type": "object",
                "title": "Schema",
                ":classNames": "from-col-1-size-3",
                "map:field": "schema",
                "properties": {},
                "ui:field": "JsonSchemaField"
            },
            "metadataObjectMap": {
                "type": "object",
                "title": "Metadata",
                ":classNames": "from-col-1-size-3",
                "map:field": "metadataObjectMap",
                "ui:field": "JsonField",
                "showTree": true,
                "properties": {},
                "additionalProperties": { "type": ["object", "string"]}
            },
            "rels": {
                "type": "object",
                "title": " ",
                ":classNames": "grid-2-columns from-col-1-size-3",
                "properties": {
                    "fwdRelations": {
                        "type": "array",
                        "ui:hideHeader": true,
                        "ui:readonly": true,
                        "ui:addable": false,
                        "ui:removable": false,
                        "ui:orderable": false,
                        "title": "Forward Relations",
                        "ui:sectionType": "label",
                        "map:array": "fwdRelations",
                        "items": {
                            "type": "object",
                            "properties": {
                                "path": {"type": "string", "title": " ", "map:field": "sourceProperty"},
                                "objectSchema": {
                                    "type": "number", "title": " ", "map:field": "targetId",
                                    "ui:field": "LookupFormField",
                                    "lookup": {
                                        "resource": "Entity Types",
                                        "api": "api:manage/objectschemas",
                                        "params": { "all": true, "size": 30 },
                                        "options": { "useCache": true },
                                        "jnx": "data.items",
                                        "id": "id",
                                        "label": "name"
                                    }
                                }
                            }
                        }
                    },
                    "backRelations": {
                        "type": "array",
                        "ui:hideHeader": true,
                        "ui:readonly": true,
                        "ui:addable": false,
                        "ui:removable": false,
                        "ui:orderable": false,
                        "title": "Back Relations",
                        "ui:sectionType": "label",
                        "map:array": "backRelations",
                        "items": {
                            "type": "object",
                            "properties": {
                                "objectSchema": {
                                    "type": "number", "title": " ", "map:field": "targetId",
                                    "ui:field": "LookupFormField",
                                    "lookup": {
                                        "resource": "Entity Types",
                                        "api": "api:manage/objectschemas",
                                        "params": { "all": true, "size": 30 },
                                        "options": { "useCache": true },
                                        "jnx": "data.items",
                                        "id": "id",
                                        "label": "name"
                                    }
                                },
                                "path": {"type": "string", "title": " ", "map:field": "sourceProperty"}
                            }
                        }
                    }
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: ({ objectSchema, onChange }) => ({
        objectSchema,
        onObjectSchemaChange: onChange
    }),
    loadData: ({ objectSchema }) => objectSchema,
    hideDataLoader: true,
    onChange({
        formObject,
        parsedProps: {
            onObjectSchemaChange
        }
    }) {
        if (!formObject) return;
        onObjectSchemaChange(formObject);
    },
    onSubmit: ({ formData }) => Promise.resolve()
});

export default ObjectSchemaEditor;
