import { Button } from 'reactstrap';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExternalLayout from '../components/layouts/ExternalLayout';
import AppContext from '../context/AppContext';

import { AuthContext } from '../context/AuthContext';
import useTitle from '../util/useTitle';
import AppTitle from '../components/AppTitle';


export function Home() {
    const auth = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        history.push(auth?.user ? (auth?.user?.user?.volunteer?.id && !auth?.user?.user?.hasCompletedVolunteerProfile && !("Voluntario" in auth?.user.roles) ? `workflow/onboarding/${auth.user.user.volunteer.id}` : "workflow/home/tray") : "login");
    }, []);

    return (<ExternalLayout/>);
}


export default Home;