export function invertMap(map, context) {
    if (!Array.isArray(map)) {
        map = Object.entries(map);
    }

    return map.map((mapping) => {
        if (!Array.isArray(mapping)) return null;
        const [key, value] = mapping;
        const pv = parseMapKey(value);
        if (!pv) return null;

        if (pv.type === "array") {
            const imap = invertMap(pv.map);
            return [
                pv.array,
                {
                    array: key,
                    map: imap,
                    initialObject: mapObject(pv.initialObject, pv.map, undefined, context)
                }
            ]
        }

        return [value, key];
    }).filter(x => !!x);
}


export function interpolate(text, scope) {
    const  fn = (typeof scope) === 'function' ? (
        (...m) => scope(m[2] || m[3]) || ''
    ) : (
        (...m) => getObject(scope, m[2] || m[3]) || ''
    );

    return text.replace(/\$(([a-zA-Z]+)|{([^}]+)})/g, fn);
};


export function interpolateMap(map, scope) {
    map = Array.isArray(map) ? map : Object.entries(map);
    return map.map(kv => kv.map(k => {
        k = parseMapKey(k);
        switch (k.type) {
            case "path": return { type: "path", value: interpolate(k.value, scope) };
            case "const": return k;
            case "array": return {
                type: "array",
                array: interpolate(k.array, scope),
                map: interpolateMap(k.map, scope),
                initialObject: k.initialObject,
            };
            default: return k;
        }
    }));
};


export function mapObject(object, map, initialObject, context) {
    if (!Array.isArray(map)) {
        map = Object.entries(map);
    }

    let result = initialObject || {};

    const mappings = map.reduce((_, mapping) => {
        const [key, value] = mapping || [];
        let pk = parseMapKey(key);
        const pv = parseMapKey(value);
        if (!pk || !pv) return _;

        while (pk.type === 'default' || pk.type === 'writeonly') pk = pk.path;

        if (pk.type !== 'path' && pk.type !== 'default') {
            // only perform assign for path keys
        } else {
            const depth = (pk.value || '').split(/\.|\[/).length + (pk.value !== '');
            _.push({depth, pk, pv});
        }

        return _;
    }, []);

    mappings.sort(({depth: depthA}, {depth: depthB}) => (
        depthA - depthB
    ));

    mappings.forEach(({pk, pv}) => {
        const mappedValue = resolvePath(pv, pk, object, result, context);

        if (mappedValue !== null && mappedValue !== undefined) {
            if (pk.value === '') {
                result = mappedValue;
            } else {
                setObject(result, pk.value, mappedValue);
            }
        }
    });

    return result;
}


export const CUSTOM_PATHS = {};


export function resolvePath(pv, pk, object, initialObject, context) {
    let result = null;
    switch (pv.type) {
        case 'path':
            result = getObject(object, pv.value);
            break;
        case 'default':
            result = resolvePath(pv.path, pk, object, initialObject, context);
            if (!result) {
                result = resolvePath(pv.default, pk, object, initialObject, context);;
            }
            break;
        case 'interpolate':
            result = interpolate(pv.value, { object, initialObject, context });;
            break;
        case 'context':
            result = getObject(context, pv.value);
            break;
        case 'writeonly':
            result = null;
            break;
        case 'readonly':
            result = resolvePath(pv.value, pk, object, initialObject, context)
            break;
        case 'array': {
            let array = getObject(object, pv.array);
            if (array === undefined) { array = []; }
            else if (!Array.isArray(array)) { array = [array]; }
            let ioArray = getObject(initialObject, pk.value) || [];
            if (ioArray === undefined) { ioArray = []; }
            else if (!Array.isArray(ioArray)) { ioArray = [ioArray]; }

            while (array.length < ioArray.length) {
                array.push(undefined);
            }

            result = array.map(
                (item, idx) => mapObject(item, pv.map, ioArray[idx], context)
            ).filter(item => !isEmptyObject(item));
            if (result.length === 0) {
                result = null;
            }
        } break;
        default:
            if (CUSTOM_PATHS[pv.type]) {
                result = CUSTOM_PATHS[pv.type].resolve(pv, pk, object, initialObject, context);
            } else {
                result = pv.value;
            }
            break;
    }
    return result;
}


export function parseMapKey(mapKey) {
    if (typeof (mapKey) === 'string') {
        return { type: 'path', value: mapKey };
    }
    if (!mapKey) return null;

    if (mapKey.const) {
        return { type: 'const', value: mapKey.const };
    } else if (mapKey.interpolate) {
        return { type: 'interpolate', value: mapKey.interpolate };
    } else if (mapKey.context) {
        return { type: 'context', value: mapKey.context };
    } else if (mapKey.readonly) {
        return { type: 'readonly', value: parseMapKey(mapKey.readonly) };
    } else if (mapKey.writeonly) {
        return { type: 'writeonly', path: parseMapKey(mapKey.writeonly) };
    } else if (mapKey.default) {
        return { type: 'default', default: parseMapKey(mapKey.default), path: parseMapKey(mapKey.path) };
    } else if (mapKey.array && mapKey.map) {
        return { type: 'array', array: mapKey.array, map: mapKey.map, initialObject: mapKey.initialObject };
    } else {
        return Object.keys(mapKey).reduce((_, type) => {
            if (CUSTOM_PATHS[type]) return { type, ...CUSTOM_PATHS[type].parse(mapKey) };
            return _;
        }, null) || mapKey;
    }
}


export function parsePath(path) {
    if (path === "") return [];
    if (path.type) {
        if (path.type === 'path') {
            path = path.value;
        } else {
            throw new Error("given path key is not a path key.");
        }
    }

    if (typeof path.split !== 'function') {
        console.log("parsePath", path);
    }
    const parsed = path.split(".").reduce((_, component) => {
        try {
            component.split('[').forEach((item, index) => {
                const attr = item.split(']')[0];
                const accessType = index ? 'array' : 'object';
                _.push({ accessType, attr });
            })
            return _;
        } catch (err) {
            console.log('ERROR WITH SPLIT', err);
            throw err;
        }
    }, []);

    parsed.forEach((component, index) => {
        component.nextAccessType = (parsed[index + 1] || {}).accessType;
    })

    return parsed;
}


export function parseRelPath(relpath) {
    if (relpath.valid) return relpath;
    const m = /^((\$\.)|(\.+))?(\w+(\.\w+)*)?$/.exec(relpath);
    if (m) {
        const p2 = m[4] ? m[4].split('.') : [];
        if (m[2]) {
            return { valid: true, absolute: true, path: p2 };
        } else {
            return { valid: true, relative: true, path: p2, ancestor: m[3] ? m[3].length - 1 : 0 };
        }
    } else {
        return { valid: false };
    }
}


export function concatenatePaths(...paths) {
    if (!paths.length) { return ''; }
    const first = (paths.shift() || '').split('.');
    return (paths || []).reduce((_, relpath) => {
        if (relpath === undefined || relpath === null) return _;
        const parsed = parseRelPath(relpath);
        if (parsed.valid) {
            if (parsed.absolute) return parsed.path;
            let i = parsed.ancestor;
            while (i > 0 && _.length > 0) {
                _.pop();
                i -= 1;
            }
            _.push(...parsed.path);
        }
        return _;
    }, first).join('.');
}

export function splitPath(path) {
    const components = path.split('.');
    const leaf = components.pop();
    return [components.length ? components.join('.') : null, leaf];
}


export function getObject(object, path) {
    const components = parsePath(path);

    while (components.length) {
        const current = components.shift();
        let { attr } = current;
        const { accessType } = current;
        if (object !== undefined && object !== null) {
            if (accessType === "array" && (attr | 0) < 0) {
                attr = object.length + (attr | 0);
            }
            object = object[attr];
        }
    }

    return object;
}

export function setObject(object, path, value) {
    if (path === '') {
        Object.assign(object, value);
        return;
    }
    const components = parsePath(path);
    const lastComponent = components.pop();

    while (components.length) {
        const { attr, nextAccessType } = components.shift();
        if (object[attr] === undefined || object[attr] === null) {
            if (nextAccessType === 'array') {
                object[attr] = [];
            } else { // if(nextAccessType === 'object'){
                object[attr] = {};
            }
        }
        object = object[attr];
    }

    const { attr } = lastComponent;
    object[attr] = value;
}


function isObject(value) {
    return value !== null && !Array.isArray(value) && (typeof value === 'object');
}

function isEmptyObject(value) {
    return isObject(value) && Object.keys(value).length === 0;
}

export function deleteObject(object, path) {
    const components = parsePath(path);
    const lastComponent = components.pop();

    while (components.length) {
        if (object === undefined || object === null) return;
        const { attr } = components.shift();
        object = object[attr];
    }

    if (object) {
        const { attr } = lastComponent;
        delete object[attr];
    }
}

// export function getPath(node, key, parent = '') {

//     if (!parent)
//         return key;

//     const routes = flat(node);
//     const found = Object.keys(routes).find(k => {

//         if (k.match(new RegExp(key)) && k.match(new RegExp(parent)) && k.match(new RegExp(`properties.${key}`))) {
//             if (k.split('.').includes(key) && k.split('.').includes(parent)) {
//                 return k.match(new RegExp(key));
//             }
//         }
//         return false;
//     });

//     return found ? found.replace(new RegExp(`${key}(.*)$`), key) : undefined;
// }