import { useContext, useEffect } from "react";
import Context2D from "./Context";
import useUID from "../../util/useUID";


function InAxis({
    axis,
    children
}) {
    const uid = useUID("axisEl");
    const { setOverlay } = useContext(Context2D);
    useEffect(() => {
        setOverlay(axis, uid, children);
        return () => {
            setOverlay(axis, uid, null);
        }
    }, [axis, uid, children]);

    return null;
}


export default InAxis;