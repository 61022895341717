import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { QrReader } from 'react-qr-reader';
import { Button } from 'reactstrap';
import { interpolate } from '../util/mapObject';
import useLoader from '../util/useLoader';
import UweApi from '../api/UweApi';
import Loader from './Loader';

const QRScanner = (props) => {

    const { onClose, request = null, onRefresh } = props;

    const [value, setValue] = useState(null);

    const [loading, error, loadFn] = useLoader();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [])

    useEffect(() => {


        if (!value || !request) return;

        const page = request.scope.page;
        const status = request.scope.item.metadata.status;
        const action = status === "Checked In" ? 'checkout' : 'checkin';

        const scope = {
            value,
            page,
            action
        };

        const api = interpolate(request.config.api, request.scope);

        const body = (request.config.body).reduce((acc, item) => {
            const key = Object.keys(item)[0];
            const value = interpolate(item[key], scope);
            acc[key] = value;
            return acc;
        }, {});


        loadFn(async () => {

            try {
                const response = await UweApi[request.config.method]({ api, data: body });
                if (response?.success) {
                    toast.success(`${action === 'checkin' ? 'Check In' : 'Check Out'} satisfactorio`);
                }
            } catch (ex) {
                toast.error(ex.message);
            }

            onClose();
            onRefresh();
        })



    }, [value]);

    const loaderStatus = useMemo(() => {

        const st = request?.scope?.item?.metadata?.status || '';

        if (st === 'Aprobado') {
            return 'Check In';
        } else if (st === 'Checked In') {
            return 'Check Out';
        }

        return '';
    }, [request]);

    return (
        !loading ?
            <>
                <QrReader
                    delay={300}
                    videoStyle={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    constraints={{
                        facingMode: "environment"
                    }}
                    onResult={(result, error) => {
                        if (result?.text) {
                            setValue(result.text);
                        }
                    }}
                />
                <Button onClick={() => {
                    onClose();
                    onRefresh();
                }} color='danger' style={{ paddingRight: 25, paddingLeft: 25, border: '1px solid white', position: 'absolute', bottom: 30, left: '50%', transform: 'translateX(-50%)' }}>Cerrar</Button>
            </>
            : <div style={{ padding: 25 }}><Loader centered='true'>Making {loaderStatus}</Loader></div>
    );
};

export default QRScanner;