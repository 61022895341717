import { Modal, ModalBody, ModalFooter, Button, ModalHeader, Table } from "reactstrap";
import UWEEntityApi from "../../api/UWEEntityApi";
import useResourceLoader from "../../util/useResourceLoader";
import Loader from "../../components/Loader";

const PARAMS = [
    "entityType", "entityId", "ownerId"
];

const DEFAULT_OPTIONS = [
    //  { text: "Confirmar", color: "primary", value: true },
    { text: "Cerrar", color: "secondary", value: false },
];


function UweEntityModal({
    entityType,
    entityId,
    ownerId,
    options = DEFAULT_OPTIONS,
    onConfirm: onClose,
    // extraProps: {reversible} = {},
    isOpen
}) {

    const [currentUWEEntity, loadingCurrentUWEEntity, errorLoadingCurrentUWEEntity] = useResourceLoader(() => {
        return UWEEntityApi.getCaseByEntityAndUserId({ entityName: entityType, ownerId });
    }, [], null);

    const propertiesToShow = [
        { value: 'age', label: 'Edad' },
        { value: 'antecedentes', label: 'Antecedentes' },
        { value: 'email', label: 'Email' },
        { value: 'emergencyContact', label: 'Contacto de Emergencia' },
        { value: 'emergencyPhone', label: 'Teléfeno de Emergencia' },
        { value: 'phone', label: 'Teléfeno' },
        { value: 'postal', label: 'Codigo Postal' },
        { value: 'sex', label: 'Género' },
    ];

    return (
        <Modal isOpen={isOpen}
            toggle={() => onClose()}
            className="modal-primary"
            size='lg'
            backdrop
        >
            <ModalHeader>
            {!loadingCurrentUWEEntity ? `${currentUWEEntity?.data?.name} ${currentUWEEntity?.data?.lastName}` : null}
            </ModalHeader>
            <ModalBody>
                {!loadingCurrentUWEEntity ?
                    <Table style={{ width: '100%' }}>
                        <tbody>
                            {(propertiesToShow || []).map((_, idx) => (<tr key={idx}>
                                <td style={{ width: '50%' }}>{_.label}</td>
                                <td style={{ width: '50%' }}>{currentUWEEntity?.data?.[_.value]}</td>
                            </tr>))}
                        </tbody>
                    </Table>
                    :
                    <Loader />
                }
            </ModalBody>
            <ModalFooter className="justify-content-right">{options.map(({ text, color, onClick, value }, idx) => (
                <Button key={idx}
                    onClick={() => onClick ? onClick({ closeModal: onClose }) : onClose(value)}
                    color={color || "secondary"}
                >{text || `Option ${idx}`}</Button>
            ))}
            </ModalFooter>
        </Modal>
    );
}


UweEntityModal.PARAMS = PARAMS;



export default UweEntityModal;