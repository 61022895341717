import React, { useState, useMemo, useContext, useCallback } from 'react';
import {
    Button,
    Card,
} from 'reactstrap';
import dayjs from 'dayjs';

import useResourceLoader from '../util/useResourceLoader';
import UWEEntityApi from '../api/UWEEntityApi';
import { DATETIME_FORMAT } from '../constants';
import Notification from '../components/Notification';
import { toDrDateTime } from "../util/dates";
import Loader from '../components/Loader';
import { AuthContext } from '../context/AuthContext';
import UWEFormContext from '../context/UWEFormContext';


function UWEEntityNote({
    entity,
    readonly,
    totalExpanded
}) {
    const entityId = entity?.id;
    const auth = useContext(AuthContext);
    const { pendingNotes, setPendingNotes } = useContext(UWEFormContext);

    const [newNote, setNewNote] = useState('');
    const [notes, loadingNotes, error, setNotes] = useResourceLoader(() => (entityId ? (
        UWEEntityApi.getUWEEntityNotes({ entityId })
    ) : ([])), [entityId], []);

    const externalNotes = entity?.externalData?.notes;

    const mergedNotes = useMemo(() => [
        ...(notes || []).map(({createdAt, message, user}) => ({
            date: dayjs.utc(createdAt).local(),
            createdAt, message, user
        })),
        ...(externalNotes || []).map(({
            createdDate: createdAt,
            notes: message,
            user: {fullName: name}
        }) => ({
            date: dayjs(createdAt),
            createdAt, message, user: {name}
        })),
        ...(pendingNotes || []).map(({
            createdAt, message, user
        }) => ({
            isPending: true,
            date: dayjs(createdAt), createdAt, message, user: {name: user}
        }))
    ].sort(
        (b, a) => a.date.diff(b.date)
    ), [notes, externalNotes, pendingNotes]);

    const submitNewNote = useCallback(async () => {
        if (newNote) {
            if (entityId) {
                const addedNote = await UWEEntityApi.createUWEEntityNote({ entityId, note: newNote });
                setNotes(notes => [addedNote, ...notes]);
            } else {
                setPendingNotes(pendingNotes => ([
                    ...(pendingNotes ?? []),
                    {
                        message: newNote,
                        createdAt: new Date().toISOString(),
                        user: `${auth?.user?.user?.firstName} ${auth?.user?.user?.lastName}`
                    }
                ]));
            }
            setNewNote('');
        }
    }, [newNote, UWEEntityApi, auth, entityId, setNotes, setNewNote, setPendingNotes]);

    return loadingNotes ? <Loader /> : (
        <>
            {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
            {!totalExpanded ?
                <UWEEntityNoteSidePanelComponent
                    readonly={readonly}
                    newNote={newNote} setNewNote={setNewNote} submitNewNote={submitNewNote}
                    notes={mergedNotes}
                />
                :
                <UWEEntityNoteExpandedComponent
                    readonly={readonly}
                    newNote={newNote} setNewNote={setNewNote} submitNewNote={submitNewNote}
                    notes={mergedNotes}
                />
            }
        </>
    );
}


function UWEEntityNoteSidePanelComponent({
    readonly,
    newNote, setNewNote, submitNewNote,
    notes,
}){
    return (<div className="uweentity-note-sidepanel-component">
        {!readonly ? (<Card className="uweentity-note-add">
            <textarea row="3" value={newNote} onChange={({target:{value}}) => setNewNote(value)} />
            <Button color="primary" onClick={submitNewNote}>Añadir Nota Nueva</Button>
        </Card>) : null}
        <div className="uweentity-notes-list">
            {!notes.length ? (
                <Notification color="warning">No hay notas para esta solicitud</Notification>
            ) : null}
            {notes.map(({ isPending, createdAt, message, user }, idx) => (
                <div className="uweentity-note-container" key={idx}>
                    <div className="uweentity-note-header">
                        <span className="uweentity-note-date-time">
                            {toDrDateTime(createdAt).format(DATETIME_FORMAT)}
                            {isPending ? "*" : ""}
                        </span>
                        <span className="uweentity-note-user">{user?.name || ''}</span>
                    </div>
                    <div className="uweentity-note-text">{message}</div>
                </div>
            ))}
        </div>
    </div>)
}


function UWEEntityNoteExpandedComponent({
    readonly,
    newNote, setNewNote, submitNewNote,
    notes,
}){
    return (<div className="uweentity-form-full-expanded-sidepanel uweentity-notes-expanded">
        {!readonly ? <>
            <div className="row uweentity-form-notes">
                <div className="col-lg-10">
                    <input className="form-control" placeholder="Ingrese nota aquí" type="text" value={newNote} onChange={({target:{value}}) => setNewNote(value)} />
                </div>
                <div className="col-lg-2">
                    <Button color="primary" onClick={submitNewNote}>Añadir nota</Button>
                </div>
            </div>
        </> : null}
        {!notes.length ? (
            <Notification color="warning">No hay notas para esta solicitud</Notification>
        ) : null}
        <ul className="striped-list">
            {notes.map(({ isPending, createdAt, message, user }, idx) => (
                <li key={idx}>
                    <div className='row'>
                        <div className='col-lg-2'><span className="uweentity-note-date-time">
                            {toDrDateTime(createdAt).format(DATETIME_FORMAT)}
                            {isPending ? "*" : ""}
                        </span></div>
                        <div className='col-lg-3'><span className="uweentity-note-user">{user?.name || ''}</span></div>
                        <div className='col-lg-7'><span className="uweentity-note-text">{message}</span></div>
                    </div>
                </li>
            ))}
        </ul>
    </div>);
}

export default UWEEntityNote;
