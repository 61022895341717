// import { Trans } from '@lingui/react';
import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { Button, Row, Col, Container } from 'reactstrap';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import ExternalLayout from '../components/layouts/ExternalLayout';

const LoginForm = FormComponent({
    displayName: 'Login',
    schema: {
        ":classNames": "grid-2-columns",
        title: "Iniciar sesión",
        type: "object",
        properties: {
            email: { ":classNames": "from-col-1-size-1", title: "Correo electrónico", type: "string" },
            password: { ":classNames": "from-col-2-size-1", title: "Contraseña", type: "string" },
        },
        required: ["email", "password"],
    },
    uiSchema: {
        "password": { "ui:widget": "password" },
    },
    parseProps: () => ({
        auth: useContext(AuthContext),
    }),
    async onSubmit({ formData: { email, password } }, { props: { auth } }) {
        await auth.login({ email, password });
    },
    renderFormChildren: ({ history }) => {
        function forgotPassword() {
            history.push("/forgot-password")
        }
        function signUp() {
            history.push("/anonymous/1/workflow/onboarding/new")
        }

        return (<>
            <Row>
             <Col><Button color="primary" type="submit" block>Acceder</Button></Col> 
                <Col><Button color="link" onClick={forgotPassword} block>Olvidé mi contraseña</Button></Col>
            </Row>
            <hr />

            <div className="text-center">
                <h5 className="text-bold">¿Necesitas crear una cuenta?</h5>
                 <Button color="primary" onClick={signUp} block>Registrarme</Button> 
            </div>
        </>);
    },
    renderFormSubmitted: () => (
        <Redirect path="/" />
    ),
    withoutNavigation: true
});

const Login = ({ history }) => (<ExternalLayout>
    <div className="col col-md-6 offset-md-3 div-login" >
        <LoginForm history={history} />
    </div>
</ExternalLayout>);


export default Login;