import React, { useMemo, useEffect, useState, useCallback } from "react";
import { FormGroup, Input } from "reactstrap";

import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject, concatenatePaths } from "../../util/mapObject";
import getPathFromId from "../../util/getPathFromId";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "./hooks/useOptionsLookup";
import CollapsableTreeSelect, { makeTreeNode, organizeTreeNodes, visitTrees } from "../CollapsableTreeSelect";
import { convertIfNumber } from "../JsonTreeEditor/util";

function LookupFormField(props) {
    const {
        formData,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
            type
        },
        idSchema: { $id } = {},
        uiSchema: {
            'akc:requiredIfVisible': akcRequiredIfVisible,
            'ui:readonly': uiReadonly,
            'ui:readonlyIf': uiReadonlyIf,
            'ui:onSelect': onSelectExpr,
        },
        // title,
        required: requiredProp,
        onChange: propOnChange
    } = props;
    const readonly = propReadonly || uiReadonly;
    const required = requiredProp || akcRequiredIfVisible;
    const {
        resource = "Options",
        setObjectAs,
        allowNoValue = true,
        noValue = '',
        parentId,
        collapsable, collapseLevel,
        label: labelExpr,
        id: idExpr,
        firstSelected = false,
        defaultValue: defaultValueExpr,
    } = lookup || {};
    const dataPath = useMemo(() => getPathFromId($id), [$id]);

    const objectPath = useMemo(
        () => setObjectAs ? concatenatePaths(dataPath, `..${setObjectAs}`) : null,
        [dataPath, setObjectAs]
    );
    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onSelectJnx = useJnx(onSelectExpr, { functionBinds });


    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const {
        options: lookupOptions, loadingOptions, errorLoadingOptions
    } = useOptionsLookup({
        lookup,
        rootFormData,
        path: dataPath,
    });

    const options = useParsedOptions(lookupOptions, lookup);

    function onChange({ target: { value } }) {
        if (type === "number") {
            value = (value | 0);
        }

        propOnChange(value);
    }
    
    const defaultValueJnx = useJnx(lookup.defaultValueExpr);    
    const defaultValue = useMemo(() => (
        defaultValueJnx && defaultValueJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })        
    ), [rootFormData, formContext,defaultValueJnx]);
    const dataValue = formData || defaultValue;

    useEffect(() => {      
        if ((formData === null || formData === undefined) && defaultValue !== null && defaultValue !== undefined) {
            onChange({ target: { value: defaultValue } });
        }
    }, [formData, defaultValue]);

    const labelJnx = useJnx(labelExpr);
    const idJnx = useJnx(idExpr);

    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);

    useEffect(() => {
        if(!options) {
            return;
        }

        const object = (options || []).filter(option => {
            const parsedValue = convertIfNumber(formData);
            return option.id === parsedValue;
        })[0]?.item;

        if (onSelectJnx) {
            setTimeout(() => {
                onSelectJnx.eval(object, '', { fieldPath: dataPath, object });
            }, 500);
        }

        if (!objectPath) {
            return;
        }

        setFormDataValues({[objectPath]: object});        
    }, [dataValue, options, objectPath]);

    const isDisabled = readonly || readOnlyIf || disabled;

    const fSelected = ((firstSelected && !dataValue && idJnx && options) ? idJnx.eval(options?.[0]) : null);

    return (
        <FormGroup disabled={readonly || readOnlyIf || disabled}>
            {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label> : null}
            {loadingOptions ? (<div>
                <Loader>Loading {resource}</Loader>
            </div>) : (errorLoadingOptions ? (<Notification color="danger">
                <div>{errorLoadingOptions.message}</div>
            </Notification>) : ((parentId && collapsable) ? (<CollapsableTreeSelect
                disabled={isDisabled} defaultCollapseLevel={collapseLevel}
                required={required}
                value={dataValue}
                rootNodes={options}
                onChange={onChange}
            />) : (
                <Input type="select" disabled={isDisabled} required={required} value={dataValue || fSelected || ""}  onChange={onChange}>
                    {allowNoValue ? <option value={undefined}>{noValue}</option> : null}
                    {(options || []).map((option, idx) => (
                        <option key={idx} value={idJnx ? idJnx.eval(option.item) : option.id}>{labelJnx ? labelJnx.eval(option.item) : option.label}</option>
                    ))}
                </Input>
            )))}
        </FormGroup>
    );
}

function useParsedOptions(options, {
    parentId, id, label
}) {
    const tsOptions = useMemo(() => {
        if (!options) return null;
        const tsOptions = options.map(item => makeTreeNode(item, id, label, parentId));
        if (parentId) {
            const rootNodes = organizeTreeNodes(tsOptions);
            visitTrees(rootNodes);
            tsOptions.sort((a, b) => a.visitIdx - b.visitIdx);
        }
        return tsOptions;
    }, [options, parentId]);
    return tsOptions;
}

export default LookupFormField;