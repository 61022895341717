import CatalogModal from "./CatalogModal";
import ConfirmationModal from "./ConfirmationModal";
import UweEntityModal from "./UweEntityModal";
import RejectionReason from "./RejectionReason";
import PdfPreviewModal from "./PdfPreviewModal";
import ItemDetails from "./ItemDetails";
import SendBackModal from "./SendBackModal";
import SendBackModalFinalApproval from "./SendBackModalFinalApproval";
import NumberEntryModal from "./NumberEntryModal";

const MODALS = Object.entries({
    NumberEntryModal,
    CatalogModal,
    ConfirmationModal,
    RejectionReason,
    PdfPreviewModal,
    ItemDetails,
    SendBackModal,
    SendBackModalFinalApproval,
    UweEntityModal
}).reduce((_, [name, Component]) => {
    _[name] = {
        Component,
        params: Component.PARAMS,
    };

    return _;
}, {});


export default MODALS;