import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import dayjs from "dayjs";
import useResourceLoader from '../../util/useResourceLoader';
import fetchApi from "../../util/fetchApi";
import checkErrors from "../../api/checkErrors";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject } from "../../util/mapObject";


function StaticField(props) {
    const {
        formData,
        formContext: { sideChannel },
        disabled,
        schema: {
            title,
            lookup,
            type
        },
        uiSchema: {
            'ui:readonly': readonly,
            'ui:convertToDate': convertToDate = false
        },
        // title,
        required,
        onChange: propOnChange
    } = props;
    const {
        resource = "Options",
        api,
        params,
        id,
        label,
        noValue = ''
    } = lookup || {};

    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const paramsString = useMemo(() => (
        rootFormData && params ? Object.entries(params).map(([k, v]) => {
            if (v && v.field) v = getObject(rootFormData, v.field);
            return v ? `${k}=${encodeURIComponent(v)}` : null;
        }).filter(s => !!s).join("&") : ""
    ), [rootFormData, params]);

    const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(
        () => api ? fetchApi.lookup.get(`${api}${paramsString ? `?${paramsString}` : ''}`).then(checkErrors) : Promise.reject(new Error("Schema error: Missing lookup data")),
        [api, paramsString]
    );

    function onChange({ target: { value } }) {
        if (type === "number") {
            value = (value | 0);
        }
        propOnChange(value);
    }

    let formatFormData = "";    
    if (convertToDate) {
        formatFormData = dayjs(formData).format('DD/MM/YYYY HH:mm:ss');
    }

    return (
        <FormGroup disabled={readonly || disabled}>
            {title ? <><label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label></> : null}
            <Input plaintext value={formatFormData || formData} />
        </FormGroup>
    );
}


export default StaticField;